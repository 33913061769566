<template>
  <div class="image message-text__wrap">
    <img
      :src="url"
      @click="onClick"
      :alt="$i18n.t('CONVERSATION.MEDIA_IS_LOADING')"
    />
    <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
      <img :src="url" class="modal-image" :alt="$t('MEDIA_IS_LOADING')" />
    </woot-modal>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss">
.image {
  max-width: 32rem;
  padding: var(--space-micro);

  > img {
    min-width: 100%;
    border-radius: var(--border-radius-medium);
  }
}
.modal-container--full-width img {
  height: auto;
}
</style>
