import AppContainer from "./Dashboard";
import conversation from "./conversation/conversation.routes";
import { routes as contactRoutes } from "./contacts/routes";
import { frontendURL } from "../../helper/URLHelper";

export default {
  routes: [
    {
      path: frontendURL("accounts/:account_id"),
      component: AppContainer,
      children: [...conversation.routes, ...contactRoutes]
    }
  ]
};
