<template>
  <section class="conversation-page">
    <chat-list
      v-show="!currentChat.id && !yclientsPhone && yclientsPhone !== ''"
      :conversation-inbox="inboxId"
      :label="label"
      :team-id="teamId"
      @conversation-load="onConversationLoad"
      @updateStatusType="updateStatusType"
    >
      <!-- <pop-over-search /> -->
      <conversation-header />
    </chat-list>
    <conversation-box
      :inbox-id="inboxId"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
      :isChatExist="isChatExist"
      v-show="currentChat.id"
    >
    </conversation-box>
    <empty-state
      v-if="!isChatExist && (yclientsPhone || yclientsPhone === '')"
      :phone="yclientsPhone"
      :phoneCode="yclientsPhoneCode"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ChatList from "../../../components/ChatList";
import ConversationBox from "../../../components/widgets/conversation/ConversationBox";
import ConversationHeader from "./ConversationHeader";
import uiSettingsMixin from "dashboard/mixins/uiSettings";
import ConversationApi from "../../../api/contacts";
import { frontendURL, conversationUrl } from "../../../helper/URLHelper";
import router from "../../../routes";
import EmptyState from "../../../components/widgets/conversation/EmptyState.vue";
import { isYclients } from "dashboard/helper/commons";
import { YCLIENTS_APPLICATION_ID } from "dashboard/constants/general";
import axios from "axios";

export default {
  components: {
    ChatList,
    ConversationBox,
    EmptyState,
    ConversationHeader
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0
    },
    conversationId: {
      type: [String, Number],
      default: 0
    },
    label: {
      type: String,
      default: ""
    },
    teamId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showSearchModal: false,
      isChatExist: true,
      isChatLoading: true,
      statusType: "open",
      yclientsPhoneCode: ""
    };
  },
  computed: {
    ...mapGetters({
      chatList: "getAllConversations",
      currentChat: "getSelectedChat",
      records: "contacts/getContacts",
      yclientsPhone: "contacts/getYclientsPhone",
      yclientsSalonId: "contacts/getYclientsSalonId",
      yclientsHash: "contacts/getYclientsHash",
      getConversationById: "getConversationById"
    }),
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const {
          is_contact_sidebar_open: isContactSidebarOpen
        } = this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    }
  },

  mounted() {
    this.$store.dispatch(
      "contacts/setYclientsSalonId",
      localStorage.getItem("salon_id")
    );
    this.$store.dispatch(
      "contacts/setYclientsHash",
      localStorage.getItem("hash")
    );
    if (localStorage.getItem("yclientsPhone") !== null) {
      this.$store.dispatch(
        "contacts/setYclientsPhone",
        localStorage.getItem("yclientsPhone")
      );
    }
    localStorage.removeItem("yclientsPhone");
    this.$store.dispatch("agents/get");
    this.initialize();
    this.setYclientsAltegioApplicationId();
    this.$watch("$store.state.route", () => this.initialize());
    this.$watch("chatList.length", () => {
      this.setActiveChat();
    });

    if (this.yclientsPhone && this.yclientsPhone.length >= 10) {
      this.setYclientsPhoneChat(this.yclientsPhone);
    } else {
      this.isChatExist = false;
    }
    window.addEventListener("message", this.handleYclientsMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleYclientsMessage);
  },
  methods: {
    async handleYclientsMessage(event) {
      const { action, payload } = event.data;
      if (!payload) {
        return;
      }
      this.$store.dispatch("contacts/setYclientsPhone", payload.phone);
      if (payload.phone && action === "phone_change") {
        this.yclientsPhoneCode = payload.phone_code.replace(/\D/g, "");
        this.setYclientsPhoneChat(
          payload.phone_code.replace(/\D/g, "") + payload.phone
        );
        this.$store.dispatch("fetchAllConversations", {
          assigneeType: "all",
          inboxId: undefined,
          labels: undefined,
          page: 1,
          status: this.statusType,
          teamId: undefined
        });
      }
    },

    async setYclientsPhoneChat(phone) {
      await this.$store.dispatch("contacts/search", {
        search: phone
      });

      const hasRecords = this.records.length > 0;
      const isPhoneValid = phone.length >= 10;

      if (hasRecords && isPhoneValid) {
        const response = await ConversationApi.getConversations(
          this.records[0].id
        );
        const conversations = response.data.payload;

        if (conversations.length > 0) {
          conversations.sort((a, b) => b.timestamp - a.timestamp);
          const conversationData = conversations[0];
          const conversationFromStore = await this.getConversationById(
            conversationData.id
          );
          if (!conversationFromStore) {
            await this.$store.dispatch("addConversation", conversationData);
          }

          const path = conversationUrl({
            accountId: conversationData.account_id,
            activeInbox: null,
            id: conversationData.id,
            label: null,
            teamId: 0
          });

          router.push({ path: frontendURL(path) });
          this.isChatExist = true;
        } else {
          this.isChatExist = false;
          router.push({ path: frontendURL("app") });
        }
      } else {
        this.isChatExist = false;
        router.push({ path: frontendURL("app") });
      }
    },

    async setYclientsAltegioApplicationId() {
      isYclients()
        ? this.$store.dispatch(
            "contacts/setApplicationId",
            YCLIENTS_APPLICATION_ID
          )
        : await this.setAltegioApplicationId();
    },

    async setAltegioApplicationId() {
      const salonId = localStorage.getItem("salon_id");
      try {
        const response = await axios.get(
          `https://webapi.flowcloud.me/api/v1/YclientsAccounts/Company/${salonId}/Application`
        );
        this.$store.dispatch(
          "contacts/setApplicationId",
          response.data.applicationId
        );
      } catch (error) {
        console.log(error);
      }
    },

    updateStatusType(value) {
      this.statusType = value;
    },

    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch("setActiveInbox", this.inboxId);
      this.setActiveChat();
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch("getConversation", this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const chat = this.findConversation();
        if (!chat) {
          return;
        }
        this.$store.dispatch("setActiveChat", chat).then(() => {
          bus.$emit("scrollToMessage");
        });
      } else {
        this.$store.dispatch("clearSelectedState");
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
