<template>
  <woot-modal :show="showAccountModal" :on-close="() => $emit('close-account-modal')" class="account-selector--modal">
    <woot-modal-header
      :header-title="$t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS')"
      :header-content="$t('SIDEBAR_ITEMS.SELECTOR_SUBTITLE')"
    />
    <div v-for="account in currentUser.accounts" :key="account.id" class="account-selector">
      <a :href="`/app/accounts/${account.id}/dashboard`">
        <i v-if="account.id === accountId" class="ion ion-ios-checkmark" />
        <label :for="account.name" class="account--details">
          <div class="account--name">{{ account.name }}</div>
          <div class="account--role">{{ account.role }}</div>
        </label>
      </a>
    </div>
    <div v-if="globalConfig.createNewAccountFromDashboard" class="modal-footer delete-item">
      <button class="button success large expanded nice" @click="$emit('show-create-account-modal')">
        {{ $t("CREATE_ACCOUNT.NEW_ACCOUNT") }}
      </button>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    showAccountModal: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      accountId: "getCurrentAccountId",
      currentUser: "getCurrentUser",
      globalConfig: "globalConfig/get",
    }),
  },
};
</script>

<style scoped lang="scss">
@import "~dashboard/assets/scss/variables";
.account-selector {
  cursor: pointer;
  padding: $space-small $space-large;

  .ion-ios-checkmark {
    font-size: $font-size-big;

    & + .account--details {
      padding-left: $space-normal;
    }
  }

  .account--details {
    padding-left: $space-large + $space-smaller;
  }

  &:last-child {
    margin-bottom: $space-large;
  }

  a {
    align-items: center;
    cursor: pointer;
    display: flex;

    .account--name {
      cursor: pointer;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      line-height: 1;
    }

    .account--role {
      cursor: pointer;
      font-size: $font-size-mini;
      text-transform: capitalize;
    }
  }
}
</style>
