import Vue from "vue";
import types from "../../mutation-types";

export const mutations = {
  [types.SET_CONTACT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data
    };
  },

  [types.CLEAR_CONTACTS]: $state => {
    Vue.set($state, "records", {});
    Vue.set($state, "sortOrder", []);
  },

  [types.SET_CONTACT_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, "count", count);
    Vue.set($state.meta, "currentPage", currentPage);
  },

  [types.SET_CONTACTS]: ($state, data) => {
    const sortOrder = data.map(contact => {
      Vue.set($state.records, contact.id, {
        ...($state.records[contact.id] || {}),
        ...contact
      });
      return contact.id;
    });
    $state.sortOrder = sortOrder;
  },

  [types.SET_CONTACT_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data
    });

    if (!$state.sortOrder.includes(data.id)) {
      $state.sortOrder.push(data.id);
    }
  },

  [types.EDIT_CONTACT]: ($state, data) => {
    Vue.set($state.records, data.id, data);
  },

  [types.UPDATE_CONTACTS_PRESENCE]: ($state, data) => {
    Object.values($state.records).forEach(element => {
      const availabilityStatus = data[element.id];
      if (availabilityStatus) {
        Vue.set(
          $state.records[element.id],
          "availability_status",
          availabilityStatus
        );
      } else {
        Vue.delete($state.records[element.id], "availability_status");
      }
    });
  },
  [types.SET_YCLIENTS_PHONE]: ($state, data) => {
    $state.yclientsPhone = data;
  },
  [types.SET_YCLIENTS_SALON_ID]: ($state, data) => {
    $state.yclientsSalonId = data;
  },
  [types.SET_YCLIENTS_HASH]: ($state, data) => {
    $state.yclientsHash = data;
  },
  [types.SET_APPLICATION_ID]: ($state, data) => {
    $state.applicationId = data;
  }
};
