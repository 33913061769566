<template>
  <div class="conv-header">
    <div class="user">
      <i class="ion-ios-arrow-back arrow" @click="setActiveChatNull" />
      <Thumbnail
        :src="currentContact.thumbnail"
        size="40px"
        :badge="chatMetadata.channel"
        :username="currentContact.name"
        :status="currentContact.availability_status"
      />
      <div class="user--profile__meta">
        <h3 class="user--name text-truncate">
          {{ isNameOnlyDigits(currentContact.name) ? "" : currentContact.name }}
          <span class="phone_number">{{ currentContact.phone_number }}</span>
        </h3>
        <span class="snoozed_status" v-if="chat.status === 'snoozed'"
          >{{ $t("CONVERSATION.HEADER.SNOOZED_UNTIL")
          }}{{
            chat.snoozed_until
              ? convertIsoToDatetime(chat.snoozed_until)
              : $t("CONVERSATION.HEADER.SNOOZED_TILL_RESPONSE")
          }}
        </span>
        <woot-button
          class="user--profile__button"
          size="small"
          variant="link"
          @click="$emit('contact-panel-toggle')"
        >
          {{ $t("CONVERSATION.HEADER.DETAILS") }}
        </woot-button>
      </div>
    </div>
    <div
      class="header-actions-wrap"
      :class="{ 'has-open-sidebar': isContactPanelOpen }"
    >
      <woot-button
        v-if="isYclientsOrAltegio()"
        style="margin-right: 10px;"
        class-names="resolve"
        icon="ion-calendar"
        :title="$t('CONVERSATION.HEADER.BUTTON_DESCRIPTION')"
        @click="() => sendPostMessage()"
      >
      </woot-button>

      <woot-button
        v-if="currentChat.status === 'open'"
        style="margin-right: 10px;"
        class-names="resolve"
        color-scheme="secondary"
        icon="ion-ios-alarm-outline"
        :title="$t('SNOOZE_MODAL.BUTTON_DESCRIPTION')"
        @click="$emit('snooze-panel-toggle')"
      >
      </woot-button>
      <more-actions :conversation-id="currentChat.id" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import router from "../../../routes";
import { frontendURL } from "../../../helper/URLHelper";
import MoreActions from "./MoreActions";
import Thumbnail from "../Thumbnail";
import agentMixin from "../../../mixins/agentMixin.js";
import {
  convertIsoToDatetime,
  isYclientsOrAltegio
} from "dashboard/helper/commons";

export default {
  components: {
    MoreActions,
    Thumbnail
  },
  mixins: [agentMixin],
  props: {
    chat: {
      type: Object,
      default: () => {}
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentChatAssignee: null,
      inboxId: null
    };
  },

  computed: {
    isNameOnlyDigits() {
      // Проверяем, содержит ли строка только цифры
      return name => /^\d+$/.test(name);
    },

    ...mapGetters({
      currentChat: "getSelectedChat",
      yclientsPhone: "contacts/getYclientsPhone"
    }),

    chatMetadata() {
      return this.chat.meta;
    },

    currentContact() {
      return this.$store.getters["contacts/getContact"](
        this.chat.meta.sender.id
      );
    }
  },
  mounted() {
    const { inbox_id: inboxId } = this.chat;
    this.inboxId = inboxId;
  },

  methods: {
    isYclientsOrAltegio,
    convertIsoToDatetime,
    setActiveChatNull() {
      this.$store.dispatch("contacts/setYclientsPhone", null);
      router.push({ path: frontendURL("app") });
    },
    removeAgent() {},
    sendPostMessage() {
      window.parent.postMessage(
        {
          action: "load_client",
          payload: `${this.currentContact.phone_number}`
        },
        "*"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.phone_number {
  font-size: 1.4rem;
}
.arrow {
  font-size: large;
  margin-right: 14px;
  cursor: pointer;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conv-header {
  flex: 0 0 var(--space-jumbo);
}

.option__desc {
  display: flex;
  align-items: center;
}

.option__desc {
  &::v-deep .status-badge {
    margin-right: var(--space-small);
    min-width: 0;
    flex-shrink: 0;
  }
}

.snoozed_status {
  color: #c69608;
  font-size: 12px;
}
</style>
