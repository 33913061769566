/* eslint no-param-reassign: 0 */

import getUuid from "widget/helpers/uuid";
import { MESSAGE_STATUS, MESSAGE_TYPE } from "shared/constants/messages";
import {
  ALTEGIO_URL,
  BILLZ_URL,
  BOARD_URL,
  YCLIENTS_URL
} from "dashboard/constants/referrerHosts";

export default () => {
  if (!Array.prototype.last) {
    Object.assign(Array.prototype, {
      last() {
        return this[this.length - 1];
      }
    });
  }
};

export const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isJSONValid = value => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTypingUsersText = (users = []) => {
  const count = users.length;
  if (count === 1) {
    const [user] = users;
    return `${user.name} is typing`;
  }

  if (count === 2) {
    const [first, second] = users;
    return `${first.name} and ${second.name} are typing`;
  }

  const [user] = users;
  const rest = users.length - 1;
  return `${user.name} and ${rest} others are typing`;
};

export const createPendingMessage = data => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const tempMessageId = getUuid();
  const { message, file } = data;
  const tempAttachments = [{ id: tempMessageId }];
  const pendingMessage = {
    ...data,
    content: message || null,
    id: tempMessageId,
    echo_id: tempMessageId,
    status: MESSAGE_STATUS.PROGRESS,
    created_at: timestamp,
    message_type: MESSAGE_TYPE.OUTGOING,
    conversation_id: data.conversationId,
    attachments: file ? tempAttachments : null
  };

  return pendingMessage;
};

export const convertIsoToDatetime = isoDatetime => {
  const datetime = new Date(isoDatetime);
  const day = datetime
    .getDate()
    .toString()
    .padStart(2, "0");
  const month = (datetime.getMonth() + 1).toString().padStart(2, "0");
  const year = datetime.getFullYear().toString();
  const hours = datetime
    .getHours()
    .toString()
    .padStart(2, "0");
  const minutes = datetime
    .getMinutes()
    .toString()
    .padStart(2, "0");

  const formattedDatetime = `${day}.${month}.${year} ${hours}:${minutes}`;
  return formattedDatetime;
};

export const getRefererHost = () => {
  if (document.referrer) {
    let url = new URL(document.referrer);
    return url.hostname;
  }
  return "";
};

export const isBoard = () => {
  const host = getRefererHost();
  return host.includes(BOARD_URL);
};

export const isYclients = () => {
  const host = getRefererHost();
  return host.includes(YCLIENTS_URL);
};

export const isAltegio = () => {
  const host = getRefererHost();
  return host.includes(ALTEGIO_URL);
};

export const isYclientsOrAltegio = () => {
  const host = getRefererHost();
  return host.includes(YCLIENTS_URL) || host.includes(ALTEGIO_URL);
};

export const isBillz = () => {
  const host = getRefererHost();
  return host.includes(BILLZ_URL);
};
