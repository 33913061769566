<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('NEW_CONVERSATION.TITLE')" />
      <conversation-form @success="onSuccess" @cancel="onCancel" :phone="phone" :phoneCode="phoneCode" />
    </div>
  </woot-modal>
</template>

<script>
import ConversationForm from "./ConversationFormEmptyState.vue";

export default {
  components: {
    ConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: "",
    },
    phoneCode: {
      type: String,
      default: "",
    },
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    onSuccess() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.modal-container {
  width: 27rem;
}
</style>
