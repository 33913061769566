import AuthAPI from "../api/auth";
import axios from "axios";
import BaseActionCableConnector from "../../shared/helpers/BaseActionCableConnector";
import { newMessageNotification } from "shared/helpers/AudioNotificationHelper";
import { isYclients } from "dashboard/helper/commons";

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubsubToken) {
    const { websocketURL = "" } = window.chatwootConfig || {};
    super(app, pubsubToken, websocketURL);
    this.CancelTyping = [];
    this.events = {
      "message.created": this.onMessageCreated,
      "message.updated": this.onMessageUpdated,
      "conversation.created": this.onConversationCreated,
      "conversation.status_changed": this.onStatusChange,
      "user:logout": this.onLogout,
      "page:reload": this.onReload,
      "assignee.changed": this.onAssigneeChanged,
      "conversation.typing_on": this.onTypingOn,
      "conversation.typing_off": this.onTypingOff,
      "conversation.contact_changed": this.onConversationContactChange,
      "presence.update": this.onPresenceUpdate
    };
  }

  isAValidEvent = data => {
    return this.app.$store.getters.getCurrentAccountId === data.account_id;
  };

  onMessageUpdated = data => {
    this.app.$store.dispatch("updateMessage", data);
  };

  onPresenceUpdate = data => {
    this.app.$store.dispatch("contacts/updatePresence", data.contacts);
    this.app.$store.dispatch("agents/updatePresence", data.users);
    this.app.$store.dispatch("setCurrentUserAvailabilityStatus", data.users);
  };

  onConversationContactChange = payload => {
    const { meta = {}, id: conversationId } = payload;
    const { sender } = meta || {};
    if (conversationId) {
      this.app.$store.dispatch("updateConversationContact", {
        conversationId,
        ...sender
      });
    }
  };

  onAssigneeChanged = payload => {
    const { id } = payload;
    if (id) {
      this.app.$store.dispatch("updateConversation", payload);
    }
    this.fetchConversationStats();
  };

  onConversationCreated = data => {
    this.app.$store.dispatch("addConversation", data);
    this.fetchConversationStats();
  };

  onLogout = () => AuthAPI.logout();

  isNameOnlyDigits(name) {
    // Проверяем, содержит ли строка только цифры
    return /^\d+$/.test(name);
  }

  onMessageCreated = async data => {
    newMessageNotification(data);
    this.app.$store.dispatch("addMessage", data);
    //хэш передает только yclients, и информацию о сообщениях нужно передавать только когда пишет клиент yclients или altegio
    const salonId = this.app.$store.getters["contacts/getYclientsSalonId"];
    const hash = this.app.$store.getters["contacts/getYclientsHash"];
    const applicationId = this.app.$store.getters["contacts/getApplicationId"];

    if (data.message_type === 0 && hash && salonId) {
      try {
        const URL = isYclients() ? "api.yclients.com" : "app.alteg.io";
        await axios.post(
          `https://${URL}/marketplace/application/new_message`,
          {
            application_id: applicationId,
            salon_id: salonId,
            phone_from: data.sender.phone_number,
            message: data.content,
            name: this.isNameOnlyDigits(data.sender.name)
              ? ""
              : data.sender.name
          },
          {
            headers: {
              Authorization: `Bearer xbrd2ac9kgg4a9fkuyxe`
            }
          }
        );
      } catch (error) {
        // Handle network or other errors
        console.error("Error sending message:", error);
      }
    }
  };

  onReload = () => window.location.reload();

  onStatusChange = data => {
    this.app.$store.dispatch("updateConversation", data);
    this.fetchConversationStats();
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch("conversationTypingStatus/create", {
      conversationId,
      user
    });
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch("conversationTypingStatus/destroy", {
      conversationId,
      user
    });
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  fetchConversationStats = () => {
    bus.$emit("fetch_conversation_stats");
  };
}

export default {
  init() {
    if (AuthAPI.isLoggedIn()) {
      const actionCable = new ActionCableConnector(
        window.WOOT,
        AuthAPI.getPubSubToken()
      );
      return actionCable;
    }
    return null;
  }
};
