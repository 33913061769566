import { frontendURL } from "../helper/URLHelper";

export const getSidebarItems = (accountId) => ({
  common: {
    routes: [
      "home",
      "inbox_dashboard",
      "inbox_conversation",
      "conversation_through_inbox",
      "notifications_dashboard",
      "profile_settings",
      "profile_settings_index",
      "label_conversations",
      "conversations_through_label",
      "team_conversations",
      "conversations_through_team",
      "notifications_index",
    ],
    menuItems: {
      assignedToMe: {
        icon: "ion-chatbox-working",
        label: "CONVERSATIONS",
        hasSubMenu: false,
        key: "",
        toState: frontendURL(`accounts/${accountId}/dashboard`),
        toolTip: "Conversation from all subscribed inboxes",
        toStateName: "home",
      },
      contacts: {
        icon: "ion-person",
        label: "CONTACTS",
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/contacts`),
        toStateName: "contacts_dashboard",
      },
      notifications: {
        icon: "ion-ios-bell",
        label: "NOTIFICATIONS",
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/notifications`),
        toStateName: "notifications_dashboard",
      },
    },
  },
  contacts: {
    routes: ["contacts_dashboard", "contacts_dashboard_manage", "contacts_labels_dashboard"],
    menuItems: {
      back: {
        icon: "ion-ios-arrow-back",
        label: "HOME",
        hasSubMenu: false,
        toStateName: "home",
        toState: frontendURL(`accounts/${accountId}/dashboard`),
      },
      contacts: {
        icon: "ion-person",
        label: "ALL_CONTACTS",
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/contacts`),
        toStateName: "contacts_dashboard",
      },
    },
  },
});
