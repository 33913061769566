/* eslint no-plusplus: 0 */
import Button from "./ui/WootButton";
import ConfirmDeleteModal from "./widgets/modal/ConfirmDeleteModal.vue";
import DeleteModal from "./widgets/modal/DeleteModal.vue";
import DropdownItem from "shared/components/ui/dropdown/DropdownItem";
import DropdownMenu from "shared/components/ui/dropdown/DropdownMenu";
import Input from "./widgets/forms/Input.vue";
import Label from "./ui/Label";
import LoadingState from "./widgets/LoadingState";
import Modal from "./Modal";
import ModalHeader from "./ModalHeader";
import Spinner from "shared/components/Spinner";
import SubmitButton from "./buttons/FormSubmitButton";
import Thumbnail from "./widgets/Thumbnail.vue";

const WootUIKit = {
  Button,
  ConfirmDeleteModal,
  DeleteModal,
  DropdownItem,
  DropdownMenu,
  Input,
  Label,
  LoadingState,
  Modal,
  ModalHeader,
  Spinner,
  SubmitButton,
  Thumbnail,
  install(Vue) {
    const keys = Object.keys(this);
    keys.pop(); // remove 'install' from keys
    let i = keys.length;
    while (i--) {
      Vue.component(`woot${keys[i]}`, this[keys[i]]);
    }
  }
};

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(WootUIKit);
}

export default WootUIKit;
