import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ru, pt } from "date-fns/locale";

export default {
  methods: {
    messageStamp(time, dateFormat = "h:mm a", locale = "en-US") {
      const date = fromUnixTime(time);
      const formatLocale = this.getLocale(locale);
      return format(date, dateFormat, { locale: formatLocale });
    },
    dynamicTime(time, locale = "en-US") {
      const date = fromUnixTime(time);
      const formatLocale = this.getLocale(locale);
      return formatDistanceToNow(date, {
        addSuffix: true,
        locale: formatLocale
      });
    },
    getLocale(locale) {
      const localeMapping = {
        ru: ru,
        pt: pt,
        "en-US": undefined // date-fns использует английский по умолчанию
      };
      return localeMapping[locale] || localeMapping["en-US"];
    }
  }
};
