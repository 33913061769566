<template>
  <form class="contact--form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="columns">
        <label :class="{ error: $v.name.$error }">
          {{ $t("CONTACT_FORM.FORM.NAME.LABEL") }}
          <input
            v-model.trim="name"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
            @input="$v.name.$touch"
          />
        </label>
      </div>
    </div>
    <div v-if="inboxesList.length > 1" class="row">
      <div class="columns">
        <label>
          {{ $t("NEW_CONVERSATION.FORM.INBOX.LABEL") }}
          <select v-model="targetInbox">
            <option v-for="inbox in inboxesList" :key="inbox.id" :value="inbox">
              {{ inbox.name }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="medium-12 columns">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t("CONTACT_FORM.FORM.PHONE_NUMBER.LABEL") }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
            @input="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t("CONTACT_FORM.FORM.PHONE_NUMBER.ERROR") }}
          </span>
        </label>
        <div
          v-if="$v.phoneNumber.$error || !phoneNumber"
          class="callout small warning"
        >
          {{ $t("CONTACT_FORM.FORM.PHONE_NUMBER.HELP") }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="medium-12 columns">
        <woot-submit-button
          :loading="inProgress"
          :button-text="$t('CONTACT_FORM.FORM.CREATE')"
        />
        <button class="button clear" @click.prevent="onCancel">
          {{ $t("CONTACT_FORM.FORM.CANCEL") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import router from "../../../routes";
import alertMixin from "shared/mixins/alertMixin";
import ConversationApi from "../../../api/contacts";
import {
  DuplicateContactException,
  ExceptionWithMessage
} from "shared/helpers/CustomErrors";
import { required } from "vuelidate/lib/validators";
import { frontendURL, conversationUrl } from "../../../helper/URLHelper";

import { isPhoneE164OrEmpty } from "shared/helpers/Validators";

export default {
  mixins: [alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({})
    },
    inProgress: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    phone: {
      type: String,
      default: ""
    },
    phoneCode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hasADuplicateContact: false,
      duplicateContact: {},
      companyName: "",
      description: "",
      email: "",
      name: "",
      message: "",
      phoneNumber: "",
      socialProfileUserNames: {
        facebook: "",
        twitter: "",
        linkedin: ""
      },
      socialProfileKeys: [
        { key: "facebook", prefixURL: "https://facebook.com/" },
        { key: "twitter", prefixURL: "https://twitter.com/" },
        { key: "linkedin", prefixURL: "https://linkedin.com/" }
      ]
    };
  },
  validations: {
    name: {
      required
    },
    description: {},
    email: {},
    companyName: {},
    phoneNumber: {
      isPhoneE164OrEmpty
    },
    bio: {}
  },

  watch: {
    contact() {
      this.setContactObject();
    }
  },
  mounted() {
    this.setContactObject();
    if (this.inboxesList.length === 1) {
      this.selectedInbox = this.inboxesList[0];
    }
  },
  computed: {
    ...mapGetters({
      records: "contacts/getContacts",
      inboxesList: "inboxes/getInboxes"
    }),
    targetInbox: {
      get() {
        return this.selectedInbox || "";
      },
      set(value) {
        this.selectedInbox = value;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    onSuccess() {
      this.$emit("success");
    },
    setContactObject() {
      this.name = "";
      this.email = "";
      this.phoneNumber = "+" + this.phoneCode + this.phone;
      this.companyName = "";
      this.description = "";
      this.socialProfileUserNames = {
        twitter: "",
        facebook: "",
        linkedin: ""
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        phone_number: this.phoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          social_profiles: this.socialProfileUserNames
        }
      };
    },
    resetDuplicate() {
      this.hasADuplicateContact = false;
      this.duplicateContact = {};
    },
    async handleSubmit() {
      this.resetDuplicate();
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      try {
        //создаем новый контакт с именем и номером
        await this.$store.dispatch("contacts/create", this.getContactObject());

        //ищем созданный контакт
        await this.$store.dispatch("contacts/search", {
          search: this.phoneNumber
        });
        //сетаем источники контакта для source_id
        await this.$store.dispatch(
          "contacts/fetchContactableInbox",
          this.records[0].id
        );
        //records это результат поиска он автоматический сетается при поиске
        //из результата поиска берем первый элемент так как это наш только что созданный контакт
        const contact = this.records[0];

        await this.$store.dispatch("contactConversations/create", {
          inboxId: this.selectedInbox.id,
          sourceId: contact.contactableInboxes[0].source_id,
          contactId: contact.id,
          message: { content: this.message }
        });
        this.onSuccess();
        this.showAlert(this.$t("CONTACT_FORM.SUCCESS_MESSAGE"));
        this.$store.dispatch("contacts/setYclientsPhone", this.phoneNumber);
        const response = await ConversationApi.getConversations(contact.id);
        const conversations = response.data.payload;

        if (conversations.length > 0) {
          conversations.sort((a, b) => b.timestamp - a.timestamp);
          const conversationData = conversations[0];
          // const conversationFromStore = await this.getConversationById(conversationData.id);
          // if (!conversationFromStore) {
          //   await this.$store.dispatch("addConversation", conversationData);
          // }
          const path = conversationUrl({
            accountId: conversationData.account_id,
            activeInbox: null,
            id: conversationData.id,
            label: null,
            teamId: 0
          });

          router.push({ path: frontendURL(path) });
        }
        this.$store.dispatch("fetchAllConversations", {
          assigneeType: "all",
          inboxId: undefined,
          labels: undefined,
          page: 1,
          status: "open",
          teamId: undefined
        });
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          this.hasADuplicateContact = true;
          this.duplicateContact = error.data;
          this.showAlert(this.$t("CONTACT_FORM.CONTACT_ALREADY_EXIST"));
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t("CONTACT_FORM.ERROR_MESSAGE"));
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}
</style>
