<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <img
        v-if="!isBoard"
        src="../../assets/images/logo-ny-light.svg"
        :alt="globalConfig.installationName"
        class="hero__logo"
      />
      <h2 class="hero__title">
        {{ $t("LOGIN.TITLE") }} {{ isBoard ? "Inbox" : "Flowsell" }}
      </h2>
    </div>
    <div class="row align-center">
      <div v-if="!email" class="small-12 medium-4 column">
        <form
          class="login-box column align-self-top align-self-center"
          @submit.prevent="login()"
        >
          <div class="column log-in-form">
            <label :class="{ error: $v.credentials.email.$error }">
              {{ $t("LOGIN.EMAIL.LABEL") }}
              <input
                v-model.trim="credentials.email"
                type="text"
                data-testid="email_input"
                :placeholder="'someone@example.com'"
                @input="$v.credentials.email.$touch"
              />
            </label>
            <label :class="{ error: $v.credentials.password.$error }">
              {{ $t("LOGIN.PASSWORD.LABEL") }}
              <input
                v-model.trim="credentials.password"
                type="password"
                data-testid="password_input"
                :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                @input="$v.credentials.password.$touch"
              />
            </label>
            <woot-submit-button
              :disabled="
                $v.credentials.email.$invalid ||
                  $v.credentials.password.$invalid ||
                  loginApi.showLoading
              "
              :button-text="$t('LOGIN.SUBMIT')"
              :loading="loginApi.showLoading"
              button-class="large expanded"
            >
            </woot-submit-button>
          </div>
        </form>
        <div class="column text-center sigin__footer">
          <p v-if="!isBoard">
            {{ $t("LOGIN.CHAT_DESCRIPTION") }}
          </p>
          <a
            v-if="isYclients()"
            style="margin-top: 1rem;"
            href="https://wa.me/+447432844083?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C%20%D1%87%D0%B0%D1%82%20%D0%B2%20Yclients"
            target="_blank"
            >{{ $t("LOGIN.CONTACT_SUPPORT") }}</a
          >
          <a
            v-if="isAltegio()"
            style="margin-top: 1rem;"
            href="https://wa.me/447432844083?text=Hello!%20I%20want%20to%20activate%20a%20chat%20in%20Altegio."
            target="_blank"
            >{{ $t("LOGIN.CONTACT_SUPPORT") }}</a
          >
        </div>
      </div>
      <woot-spinner v-else size="" />
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import globalConfigMixin from "shared/mixins/globalConfigMixin";
import WootSubmitButton from "../../components/buttons/FormSubmitButton";
import { mapGetters } from "vuex";
import {
  getRefererHost,
  isAltegio,
  isBoard,
  isYclients
} from "dashboard/helper/commons";
import {
  ALTEGIO_LANGUAGE_IDS,
  AVAILABLE_LANGUAGES_BY_ALTEGIO
} from "dashboard/constants/general";

export default {
  components: {
    WootSubmitButton
  },
  mixins: [globalConfigMixin],
  props: {
    ssoAuthToken: { type: String, default: "" },
    redirectUrl: { type: String, default: "" },
    config: { type: String, default: "" },
    email: { type: String, default: "" }
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: "",
        password: ""
      },
      loginApi: {
        message: "",
        showLoading: false
      },
      error: "",
      isBoard: false
    };
  },
  validations: {
    credentials: {
      password: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get"
    })
  },
  created() {
    if (this.ssoAuthToken) {
      this.login();
    }
  },
  mounted() {
    let hostname = localStorage.getItem("hostReferer");
    if (!hostname) {
      localStorage.setItem("hostReferer", getRefererHost());
      hostname = getRefererHost();
    }

    let selectedLocale = "ru"; // Default locale set to Russian
    const altegioLanguage = Number(localStorage.getItem("altegioLanguage"));
    if (isBoard()) {
      selectedLocale = "en";
    } else if (
      altegioLanguage &&
      AVAILABLE_LANGUAGES_BY_ALTEGIO.includes(altegioLanguage)
    ) {
      selectedLocale = ALTEGIO_LANGUAGE_IDS[altegioLanguage];
    } else if (altegioLanguage) {
      selectedLocale = "en";
    }
    this.setLocale(selectedLocale);
  },
  methods: {
    isAltegio,
    isYclients,
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit("newToastMessage", this.loginApi.message);
    },
    login() {
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email ? this.email : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken
      };
      this.$store
        .dispatch("login", credentials)
        .then(() => {
          this.showAlert(this.$t("LOGIN.API.SUCCESS_MESSAGE"));
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = "/app/login";
          }

          if (response && response.status === 401) {
            const { errors } = response.data;
            const hasAuthErrorMsg =
              errors &&
              errors.length &&
              errors[0] &&
              typeof errors[0] === "string";
            if (hasAuthErrorMsg) {
              this.showAlert(errors[0]);
            } else {
              this.showAlert(this.$t("LOGIN.API.UNAUTH"));
            }
            return;
          }
          this.showAlert(this.$t("LOGIN.API.ERROR_MESSAGE"));
        });
    }
  }
};
</script>
