<template>
  <div id="app" class="app-wrapper app-root">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <woot-snackbar-box />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WootSnackbarBox from "./components/SnackbarContainer";
import { accountIdFromPathname } from "./helper/URLHelper";
import { isBoard } from "dashboard/helper/commons";
import {
  ALTEGIO_LANGUAGE_IDS,
  AVAILABLE_LANGUAGES_BY_ALTEGIO
} from "dashboard/constants/general";

export default {
  name: "App",

  components: {
    WootSnackbarBox
  },

  computed: {
    ...mapGetters({
      getAccount: "accounts/getAccount"
    })
  },

  mounted() {
    this.$store.dispatch("setUser");
    this.initializeAccount();
    window.addEventListener("storage", function(event) {
      if (event.key === "user" && event.oldValue) {
        window.location.reload();
      }
    });
  },

  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },

    async initializeAccount() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      if (accountId) {
        await this.$store.dispatch("accounts/get");
        const { locale } = this.getAccount(accountId);

        let selectedLocale = "en"; // Default locale
        const altegioLanguage = Number(localStorage.getItem("altegioLanguage"));
        if (!isBoard()) {
          if (
            altegioLanguage &&
            AVAILABLE_LANGUAGES_BY_ALTEGIO.includes(altegioLanguage)
          ) {
            selectedLocale = ALTEGIO_LANGUAGE_IDS[altegioLanguage];
          } else if (!altegioLanguage) {
            selectedLocale = locale;
          }
        }
        this.setLocale(selectedLocale);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./assets/scss/app";
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
