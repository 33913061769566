<template>
  <woot-modal :show.sync="show" :on-close="onCancel" modal-type="right-aligned">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('SNOOZE_MODAL.TITLE')" />
      <div class="row options-wrap">
        <div class="columns options">
          <woot-submit-button
            class="option-button"
            v-for="{ label, value } in snoozeOptions"
            :key="value"
            :button-text="$t(label)"
            @click="handleSnoozeOption(value)"
          >
          </woot-submit-button>
        </div>
      </div>
      <div v-if="showDatePicker" class="date-picker">
        <input type="datetime-local" v-model="selectedDate" :min="minDate" />
        <woot-submit-button
          v-if="selectedDate"
          class="snoozeTillDate"
          :button-text="$t('SNOOZE_MODAL.SNOOZE')"
          @click="handleSnoozeDateOption"
        >
        </woot-submit-button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDatePicker: false,
      selectedDate: null,
      minDate: null,
      snoozeOptions: [
        { label: "untilReponse", value: null },
        { label: "untilHour", value: 3600 },
        { label: "untilTomorrow", value: 86400 },
        { label: "untilWeek", value: 604800 },
        { label: "untilMonth", value: 2419200 },
        { label: "untilCustom", value: "custom" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      uiFlags: "contacts/getUIFlags"
    }),
    formattedDate() {
      const date = new Date(this.selectedDate);
      const seconds = date.getTime() / 1000; // Получить секунды

      return seconds;
    }
  },

  methods: {
    onCancel() {
      this.$emit("cancel");
      this.showDatePicker = false;
      this.selectedDate = null;
    },
    onSuccess() {
      this.$emit("cancel");
    },
    handleSnoozeOption(value) {
      if (value !== "custom") {
        this.$emit("cancel");
        this.$emit("handleSnoozeOption", value);
        this.showDatePicker = false;
      } else {
        this.showDatePicker = true;
        //ограничвиваем дату выбора от текущего до будущих дат
        const currentDate = new Date();
        this.minDate = currentDate.toISOString().slice(0, 16);
      }
      this.selectedDate = null;
    },
    handleSnoozeDateOption() {
      this.$emit("cancel");
      this.$emit("handleSnoozeOption", this.formattedDate);
      this.selectedDate = null;
      this.showDatePicker = false;
    }
  }
};
</script>

<style scoped>
.options-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.option-button {
  width: 60%;
}

.date-picker {
  width: 60%;
  margin: auto;
}
</style>
