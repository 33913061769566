import { render, staticRenderFns } from "./NoteList.vue?vue&type=template&id=23dd4efa&scoped=true&"
import script from "./NoteList.vue?vue&type=script&lang=js&"
export * from "./NoteList.vue?vue&type=script&lang=js&"
import style0 from "./NoteList.vue?vue&type=style&index=0&id=23dd4efa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23dd4efa",
  null
  
)

export default component.exports