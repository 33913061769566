<template>
  <div class="message-text__wrap">
    <div
      v-if="!extractMediaFromHtml(message)"
      class="text-content"
      v-html="message"
    ></div>
    <!-- Показываем изображение, если URL ведет на изображение -->
    <div v-if="mediaData && mediaData.type === 'image'">
      <bubble-image :url="mediaData.url" />
      <div v-if="mediaData.description" class="media-description">
        {{ mediaData.description }}
      </div>
    </div>

    <!-- Показываем видео, если URL ведет на видео -->
    <div v-if="mediaData && mediaData.type === 'video'">
      <bubble-video :url="mediaData.url" />
      <div v-if="mediaData.description" class="media-description">
        {{ mediaData.description }}
      </div>
    </div>

    <div v-if="mediaData && mediaData.type === 'audio'">
      <bubble-audio :url="mediaData.url" />
      <div v-if="mediaData.description" class="media-description">
        {{ mediaData.description }}
      </div>
    </div>
  </div>
</template>

<script>
import BubbleImage from "components/widgets/conversation/bubble/Image.vue";
import BubbleVideo from "components/widgets/conversation/bubble/Video.vue";
import BubbleAudio from "components/widgets/conversation/bubble/Audio.vue";

export default {
  components: { BubbleImage, BubbleVideo, BubbleAudio },
  props: {
    message: {
      type: String,
      default: ""
    },
    readableTime: {
      type: String,
      default: ""
    },
    isEmail: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mediaData() {
      return this.extractMediaFromHtml(this.message);
    }
  },
  methods: {
    extractMediaFromHtml(htmlString) {
      let text;
      // Создаем новый парсер
      const parser = new DOMParser();

      // Парсим текст как HTML
      const doc = parser.parseFromString(htmlString, "text/html");

      // Ищем первый элемент <a> и получаем его атрибут href
      const link = doc.querySelector("a");
      const p = doc.querySelector("p");
      if (!link) {
        return null;
      }
      text = p.textContent.trim();
      // Регулярные выражения для изображений и видео
      const imageExtensions = /\.(jpg|jpeg|png|gif)$/i;
      const videoExtensions = /\.(mp4|webm|ogg)$/i;
      const audioExtensions = /\.(mp3|oga)$/i;

      // Проверка на изображение и видео
      if (imageExtensions.test(link.href)) {
        return {
          type: "image",
          url: link.href,
          description: this.removeTextBeforePattern(text)
        };
      } else if (videoExtensions.test(link.href)) {
        return {
          type: "video",
          url: link.href,
          description: this.removeTextBeforePattern(text)
        };
      } else if (audioExtensions.test(link.href)) {
        return {
          type: "audio",
          url: link.href,
          description: this.removeTextBeforePattern(text)
        };
      }

      // Если медиа не найдено, возвращаем null
      return null;
    },
    removeTextBeforePattern(inputString, pattern = " - ") {
      const patternIndex = inputString.indexOf(pattern);

      if (patternIndex !== -1) {
        return inputString.slice(patternIndex + pattern.length);
      } else {
        return null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.text-content {
  overflow: auto;

  &::v-deep {
    ul,
    ol {
      margin-left: var(--space-normal);
    }
  }
}
.media-description {
  padding: 0.8rem 1.6rem;
}
</style>
