<template>
  <div class="flex-container actions--container">
    <resolve-action :conversation-id="currentChat.id" :status="currentChat.status" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import alertMixin from "shared/mixins/alertMixin";
import ResolveAction from "../../buttons/ResolveAction";

export default {
  components: {
    ResolveAction,
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showConversationActions: false,
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: "getSelectedChat",
    }),
  },
  methods: {
    mute() {
      this.$store.dispatch("muteConversation", this.currentChat.id);
      this.showAlert(this.$t("CONTACT_PANEL.MUTED_SUCCESS"));
      this.toggleConversationActions();
    },
    unmute() {
      this.$store.dispatch("unmuteConversation", this.currentChat.id);
      this.showAlert(this.$t("CONTACT_PANEL.UNMUTED_SUCCESS"));
      this.toggleConversationActions();
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.hideConversationActions();
    },
    toggleConversationActions() {
      this.showConversationActions = !this.showConversationActions;
    },
    hideConversationActions() {
      this.showConversationActions = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~dashboard/assets/scss/mixins";

.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}

.actions--container {
  position: relative;
}

.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}

.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
</style>
