<template>
  <select
    v-model="activeStatus"
    class="filter status--filter"
    @change="onTabChange()"
  >
    <option
      class="option"
      v-for="item in items"
      :key="item['key']"
      :value="item['key']"
    >
      {{ item["name"] }}
    </option>
  </select>
</template>

<script>
import wootConstants from "../../../constants";

export default {
  data: () => ({
    activeStatus: wootConstants.STATUS_TYPE.OPEN
  }),
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onTabChange() {
      this.$store.dispatch("setChatFilter", this.activeStatus);
      this.$emit("statusFilterChange", this.activeStatus);
    }
  }
};
</script>
<style scoped>
.filter.status--filter {
  width: 90px !important;
  padding-left: 5px !important;
  padding-right: 2.1rem !important;
  margin-right: 10px !important;
}
</style>
