<template>
  <div class="search-wrap">
    <div class="search">
      <div class="bottom-nav app-context-menu" @click="toggleOptions">
        <agent-details @show-options="toggleOptions" />
        <options-menu
          :show="showOptionsMenu"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @close="toggleOptions"
        />
      </div>
      <div class="icon" @click="handleSearchButtonClick">
        <i class="ion-ios-search-strong search--icon" />
      </div>
    </div>

    <account-selector
      :show-account-modal="showAccountModal"
      @close-account-modal="toggleAccountModal"
      @show-create-account-modal="openCreateAccountModal"
    />

    <add-account-modal
      :show="showCreateAccountModal"
      @close-account-create-modal="closeCreateAccountModal"
    />

    <woot-modal :show.sync="showAddLabelModal" :on-close="hideAddLabelPopup">
      <add-label-modal @close="hideAddLabelPopup" />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSidebarItems } from "../../../i18n/default-sidebar";
import AgentDetails from "../../../../dashboard/components/layout/sidebarComponents/AgentDetails.vue";
import OptionsMenu from "../../../../dashboard/components/layout/sidebarComponents/OptionsMenu.vue";
import AccountSelector from "../../../../dashboard/components/layout/sidebarComponents/AccountSelector.vue";
import AddAccountModal from "../../../../dashboard/components/layout/sidebarComponents/AddAccountModal.vue";
import router from "../../../routes";
import {
  ALTEGIO_LANGUAGE_IDS,
  AVAILABLE_LANGUAGES_BY_ALTEGIO
} from "dashboard/constants/general";
import { accountIdFromPathname } from "dashboard/helper/URLHelper";

export default {
  components: {
    AgentDetails,
    OptionsMenu,
    AccountSelector,
    AddAccountModal
  },
  data() {
    return {
      showOptionsMenu: false,
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false
    };
  },

  computed: {
    ...mapGetters({
      getAccount: "accounts/getAccount",
      currentUser: "getCurrentUser",
      globalConfig: "globalConfig/get",
      inboxes: "inboxes/getInboxes",
      accountId: "getCurrentAccountId",
      currentRole: "getCurrentRole",
      accountLabels: "labels/getLabelsOnSidebar"
    }),

    sidemenuItems() {
      return getSidebarItems(this.accountId);
    },
    accessibleMenuItems() {
      // get all keys in menuGroup
      const groupKey = Object.keys(this.sidemenuItems);

      let menuItems = [];
      // Iterate over menuGroup to find the correct group
      for (let i = 0; i < groupKey.length; i += 1) {
        const groupItem = this.sidemenuItems[groupKey[i]];
        // Check if current route is included
        const isRouteIncluded = groupItem.routes.includes(this.currentRoute);
        if (isRouteIncluded) {
          menuItems = Object.values(groupItem.menuItems);
        }
      }

      return this.filterMenuItemsByRole(menuItems);
    },
    currentRoute() {
      return this.$store.state.route.name;
    }
  },
  watch: {
    currentUser(newUserInfo, oldUserInfo) {
      if (!oldUserInfo.email && newUserInfo.email) {
        this.setChatwootUser();
        this.setUserLanguage();
      }
    }
  },
  mounted() {
    this.$store.dispatch("labels/get");
    this.$store.dispatch("inboxes/get");
    this.$store.dispatch("notifications/unReadCount");
    this.setChatwootUser();
  },
  methods: {
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    handleSearchButtonClick() {
      router.push(`/app/accounts/${this.accountId}/contacts?page=1`);
    },
    setChatwootUser() {
      if (!this.currentUser.email || !this.globalConfig.chatwootInboxToken) {
        return;
      }
      window.$chatwoot.setUser(this.currentUser.email, {
        name: this.currentUser.name,
        email: this.currentUser.email,
        avatar_url: this.currentUser.avatar_url,
        identifier_hash: this.currentUser.hmac_identifier
      });
    },
    filterMenuItemsByRole(menuItems) {
      if (!this.currentRole) {
        return [];
      }
      return menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    async setUserLanguage() {
      await this.$store.dispatch("accounts/get");
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);
      const altegioLanguageNumber = Number(
        localStorage.getItem("altegioLanguage")
      );
      const { locale } = this.getAccount(accountId);
      if (
        altegioLanguageNumber &&
        AVAILABLE_LANGUAGES_BY_ALTEGIO.includes(altegioLanguageNumber) &&
        locale !== ALTEGIO_LANGUAGE_IDS[altegioLanguageNumber]
      ) {
        await this.$store.dispatch("accounts/update", {
          name: this.currentUser.name,
          locale: ALTEGIO_LANGUAGE_IDS[altegioLanguageNumber]
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-wrap {
  position: relative;
  height: 55px;
  background-color: #f7f9fc;
}

.search {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  padding: var(--space-one) 5px 10px 5px;
}

.search--input {
  align-items: center;
  border: 0;
  color: var(--color-body);
  cursor: pointer;
  width: 100%;
  display: flex;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  text-align: left;
  line-height: var(--font-size-large);
}

.search--icon {
  color: var(--s-600);
  font-size: var(--font-size-large);
  padding: 0 var(--space-small) 0 0;
  &:hover {
    cursor: pointer;
    color: var(--w-500);
  }
}

.icon {
  display: flex;
  &:hover {
    cursor: pointer;
    color: var(--w-500);
  }
}

//sidebar item
@import "~dashboard/assets/scss/variables";

.account-selector--modal {
  .modal-container {
    width: 40rem;
  }
}

.account-selector {
  cursor: pointer;
  padding: $space-small $space-large;

  .ion-ios-checkmark {
    font-size: $font-size-big;

    & + .account--details {
      padding-left: $space-normal;
    }
  }

  .account--details {
    padding-left: $space-large + $space-smaller;
  }

  &:last-child {
    margin-bottom: $space-large;
  }

  a {
    align-items: center;
    cursor: pointer;
    display: flex;

    .account--name {
      cursor: pointer;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      line-height: 1;
    }

    .account--role {
      cursor: pointer;
      font-size: $font-size-mini;
      text-transform: capitalize;
    }
  }
}

.app-context-menu {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 6rem;
}

.current-user--options {
  font-size: $font-size-big;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
}

.teams-sidebar-menu + .nested.vertical.menu {
  padding-left: calc(var(--space-medium) - var(--space-one));
}
</style>
