export const frontendURL = (path, params) => {
  if (params) {
    const searchParams = new URLSearchParams(params);
    const queryString = searchParams.toString();
    return `/app/${path}?${queryString}`;
  } else {
    return `/app/${path}`;
  }
};

export const conversationUrl = ({ accountId, activeInbox, id, label, teamId }) => {
  if (activeInbox) {
    return `accounts/${accountId}/inbox/${activeInbox}/conversations/${id}`;
  }
  if (label) {
    return `accounts/${accountId}/label/${label}/conversations/${id}`;
  }
  if (teamId) {
    return `accounts/${accountId}/team/${teamId}/conversations/${id}`;
  }
  return `accounts/${accountId}/conversations/${id}`;
};

export const accountIdFromPathname = (pathname) => {
  const isInsideAccountScopedURLs = pathname.includes("/app/accounts");
  const urlParam = pathname.split("/")[3];
  // eslint-disable-next-line no-restricted-globals
  const isScoped = isInsideAccountScopedURLs && !isNaN(urlParam);
  const accountId = isScoped ? Number(urlParam) : "";
  return accountId;
};
