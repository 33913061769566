<template>
  <div class="medium-3 bg-white contact--panel">
    <span class="close-button" @click="onPanelToggle">
      <i class="ion-chevron-right" />
    </span>
    <contact-info :contact="contact" :channel-type="channelType" />
    <conversation-labels :conversation-id="conversationId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import agentMixin from "../../../mixins/agentMixin";

import ContactInfo from "./contact/ContactInfo";
import ConversationLabels from "./labels/LabelBox.vue";

export default {
  components: {
    ContactInfo,
    ConversationLabels
  },
  mixins: [alertMixin, agentMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true
    },
    inboxId: {
      type: Number,
      default: undefined
    },
    onToggle: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      currentChat: "getSelectedChat",
      currentUser: "getCurrentUser"
    }),
    channelType() {
      return this.currentChat.meta?.channel;
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters["contacts/getContact"](this.contactId);
    }
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails();
      }
    },
    contactId() {
      this.getContactDetails();
    }
  },
  mounted() {
    this.getContactDetails();
  },
  methods: {
    onPanelToggle() {
      this.onToggle();
    },
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch("contacts/show", { id: this.contactId });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~dashboard/assets/scss/variables";

.contact--panel {
  background: white;
  border-left: 1px solid var(--color-border);
  font-size: $font-size-small;
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    margin-bottom: var(--space-normal);
    border-bottom: 1px solid var(--color-border-light);
  }
  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }
    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: $space-normal;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.conversation--actions {
  margin-bottom: var(--space-normal);
}

.option__desc {
  display: flex;
  align-items: center;

  &::v-deep .status-badge {
    margin-right: var(--space-small);
    min-width: 0;
    flex-shrink: 0;
  }
}
</style>
