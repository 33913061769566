<template>
  <div class="is-video">
    <div class="video message-text__wrap">
      <video :src="url" @click="onClick" controls />
      <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
        <video :src="url" class="modal-image" controls />
      </woot-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss">
.is-video {
  padding: 0;
  overflow: hidden;
  .video {
    max-width: 32rem;
    padding: var(--space-micro);

    > video {
      border-radius: var(--border-radius-medium);
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }
}
.modal-container--full-width video {
  height: auto;
}
</style>
