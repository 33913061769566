<template>
  <div class="conversation-details-wrap">
    <conversation-header
      v-if="currentChat.id"
      :chat="currentChat"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
      @snooze-panel-toggle="toggleSnoozeModal"
    />
    <div class="messages-and-sidebar">
      <messages-view
        v-show="currentChat.id"
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        @contact-panel-toggle="onToggleContactPanel"
      />
      <empty-state v-show="!currentChat.id" />

      <div v-show="showContactPanel" class="conversation-sidebar-wrap">
        <contact-panel
          v-if="showContactPanel"
          :conversation-id="currentChat.id"
          :inbox-id="currentChat.inbox_id"
          :on-toggle="onToggleContactPanel"
        />
      </div>
    </div>
    <snooze-modal
      :show="showSnoozeModal"
      @cancel="toggleSnoozeModal"
      @handleSnoozeOption="changeSnoozeStatus"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContactPanel from "dashboard/routes/dashboard/conversation/ContactPanel";
import ConversationHeader from "./ConversationHeader";
import MessagesView from "./MessagesView";
import EmptyState from "./EmptyState";
import SnoozeModal from "dashboard/routes/dashboard/conversation/SnoozeModal.vue";

export default {
  components: {
    SnoozeModal,
    MessagesView,
    EmptyState,
    ContactPanel,
    ConversationHeader
  },
  data() {
    return {
      showContactPanel: false,
      showSnoozeModal: false
    };
  },

  props: {
    inboxId: {
      type: [Number, String],
      default: "",
      required: false
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false
    },
    isChatExist: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentChat: "getSelectedChat",
      yclientsPhone: "contacts/getYclientsPhone"
    })
  },
  watch: {
    "currentChat.inbox_id"(inboxId) {
      if (inboxId) {
        this.$store.dispatch("inboxAssignableAgents/fetch", { inboxId });
      }
    }
  },
  methods: {
    onToggleContactPanel() {
      this.showContactPanel = !this.showContactPanel;
    },
    toggleSnoozeModal() {
      this.showSnoozeModal = !this.showSnoozeModal;
    },
    changeSnoozeStatus(value) {
      const MONTH_IN_SECONDS = 2629800;
      this.isLoading = true;
      let status = "snoozed";
      let snoozed_until;
      //если value меньше месяца значит нужно добавить Date.now() потому что он выбрал готовую опцию
      if (value < MONTH_IN_SECONDS) {
        snoozed_until = value ? Math.round(Date.now() / 1000) + value : value;
      } else {
        //если больше значит он выбрал определенную дату
        snoozed_until = value;
      }
      this.$store
        .dispatch("toggleStatus", {
          conversationId: this.currentChat.id,
          snoozed_until,
          status
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~dashboard/assets/scss/woot";

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  border-left: 1px solid var(--color-border);
  background: var(--color-background-light);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: calc(100vh - var(--space-jumbo));
}

.conversation-sidebar-wrap {
  position: absolute;
  height: 550px;
  width: 300px;
  flex: 0 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background: white;
  flex-basis: 28rem;
  top: 0;
  right: 0px;
  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 10px 0px 10px 10px;
  }
}
</style>
