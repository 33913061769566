<template>
  <div class="is-audio">
    <div class="audio message-text__wrap">
      <audio :src="url" controls />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.is-audio {
  padding: 0;
  overflow: hidden;
  .audio {
    max-width: 32rem;
    padding: var(--space-micro);

    > audio {
      border-radius: var(--border-radius-medium);
      display: inline-block;
      vertical-align: middle;
      width: 100%;
    }
  }
}
</style>
