<template>
  <div class="conversations-list-wrap">
    <slot></slot>
    <div class="chat-list__top">
      <i
        class="ion-ios-arrow-back arrow"
        @click="backHome"
        v-if="activeLabel !== 'default'"
      />
      <h1
        class="page-title text-truncate"
        :class="{ 'chat-list__label-title': this.activeLabel !== 'default' }"
        :title="pageTitle"
      >
        {{ pageTitle }}
        <span style="font-size: medium;">
          ({{ this.filterItems[0].count }})
        </span>
      </h1>
      <div>
        <select
          v-model="activeLabel"
          class="filter status--filter labels-filter"
          @change="onLabelChange()"
        >
          <option value="default" disabled selected>{{
            $t("LABEL_MGMT.HEADER")
          }}</option>
          <option
            class="option"
            v-for="label in this.accountLabels"
            :key="label.id"
            :value="label.title"
          >
            {{ label.title }}
          </option>
        </select>
        <chat-filter
          @statusFilterChange="updateStatusType"
          :items="filterItems"
        />
      </div>
    </div>

    <p v-if="!chatListLoading && !chatLists.length" class="content-box">
      {{ $t("CHAT_LIST.LIST.404") }}
    </p>

    <div class="conversations-list">
      <conversation-card
        v-for="chat in activeStatus === 'all' ? chatLists : conversationList"
        :key="chat.id"
        :active-label="label"
        :team-id="teamId"
        :chat="chat"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner"></span>
      </div>

      <woot-button
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        variant="clear"
        size="expanded"
        @click="fetchConversations"
      >
        {{ $t("CHAT_LIST.LOAD_MORE_CONVERSATIONS") }}
      </woot-button>

      <p
        v-if="
          conversationList.length &&
            hasCurrentPageEndReached &&
            !chatListLoading
        "
        class="text-center text-muted end-of-list-text"
      >
        {{ $t("CHAT_LIST.EOF") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ChatFilter from "./widgets/conversation/ChatFilter";
import ConversationCard from "./widgets/conversation/ConversationCard";
import timeMixin from "../mixins/time";
import conversationMixin from "../mixins/conversations";
import wootConstants from "../constants";
import { frontendURL } from "dashboard/helper/URLHelper";
import router from "dashboard/routes";

export default {
  components: {
    ConversationCard,
    ChatFilter
  },
  mixins: [timeMixin, conversationMixin],
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0
    },
    teamId: {
      type: [String, Number],
      default: 0
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ALL,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      activeLabel: "default"
    };
  },
  computed: {
    ...mapGetters({
      chatLists: "getAllConversations",
      mineChatsList: "getMineChats",
      allChatList: "getAllStatusChats",
      unAssignedChatsList: "getUnAssignedChats",
      chatListLoading: "getChatListLoadingStatus",
      currentUserID: "getCurrentUserID",
      activeInbox: "getSelectedInbox",
      conversationStats: "conversationStats/getStats",
      accountLabels: "labels/getLabelsOnSidebar",
      accountId: "getCurrentAccountId"
    }),

    filterItems() {
      return this.$t("CHAT_LIST.CHAT_STATUS_ITEMS").map(item => {
        const count = this.conversationStats[item.COUNT_KEY] || 0;
        return {
          key: item.VALUE,
          name: item.TEXT,
          count
        };
      });
    },

    inbox() {
      return this.$store.getters["inboxes/getInbox"](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters["conversationPage/getCurrentPageFilter"](
        this.activeAssigneeTab
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters["conversationPage/getHasEndReached"](
        this.activeAssigneeTab
      );
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox ? this.conversationInbox : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        page: this.currentPage + 1,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId ? this.teamId : undefined
      };
    },
    pageTitle() {
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `${this.label}`;
      }
      return this.$t("CHAT_LIST.TAB_HEADING");
    },
    conversationList() {
      let conversationList = [];
      const filters = this.conversationFilters;
      conversationList = [...this.allChatList(filters)];

      return conversationList;
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters["teams/getTeam"](this.teamId);
      }
      return {};
    }
  },
  watch: {
    activeTeam() {
      this.resetAndFetchData();
    },
    conversationInbox() {
      this.resetAndFetchData();
    },
    label() {
      this.resetAndFetchData();
    }
  },
  mounted() {
    this.$store.dispatch("setChatFilter", this.activeStatus);
    this.resetAndFetchData();

    bus.$on("fetch_conversation_stats", () => {
      this.$store.dispatch("conversationStats/get", this.conversationFilters);
    });
  },
  methods: {
    frontendURL,
    resetAndFetchData() {
      this.$store.dispatch("conversationPage/reset");
      this.$store.dispatch("emptyAllConversations");
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch("fetchAllConversations", this.conversationFilters)
        .then(() => this.$emit("conversation-load"));
    },
    updateStatusType(index) {
      if (this.activeStatus !== index) {
        this.activeStatus = index;
        this.resetAndFetchData();
        this.$emit("updateStatusType", this.activeStatus);
      }
    },
    onLabelChange() {
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/label/${this.activeLabel}`
        )
      });
    },
    backHome() {
      this.activeLabel = "default";
      this.$router.push(`/app`);
    }
  }
};
</script>

<style scoped lang="scss">
.filter.labels-filter {
  min-width: 90px !important;
  padding-left: 5px !important;
  padding-right: 2.1rem !important;
  margin-right: 10px !important;
}
.arrow {
  font-size: large;
  margin-left: 10px;
  cursor: pointer;
}
.chat-list__label-title {
  font-size: 1.7rem !important;
  margin-left: 0px !important;
}
@import "~dashboard/assets/scss/woot";
.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}
.conversations-list-wrap {
  flex-shrink: 0;
  width: 100%;
}
</style>
