<template>
  <section class="contacts-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <empty-state v-if="showSearchEmptyState" :title="$t('CONTACTS_PAGE.LIST.404')" />
    <empty-state v-else-if="!isLoading && !contacts.length" :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')" />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t("CONTACTS_PAGE.LIST.LOADING_MESSAGE") }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { VeTable } from "vue-easytable";

import Spinner from "shared/components/Spinner.vue";
import Thumbnail from "dashboard/components/widgets/Thumbnail.vue";
import EmptyState from "dashboard/components/widgets/EmptyState.vue";
import timeMixin from "dashboard/mixins/time";

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [clickaway, timeMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    onClickContact: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeContactId: {
      type: [String, Number],
      default: "",
    },
    sortParam: {
      type: String,
      default: "name",
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: (params) => this.$emit("on-sort-change", params),
      },
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.contacts.map((item) => {
        // Note: The attributes used here is in snake case
        // as it simplier the sort attribute calculation
        return {
          ...item,
        };
      });
    },
    columns() {
      return [
        {
          field: "name",
          key: "name",
          title: this.$t("CONTACTS_PAGE.LIST.TABLE_HEADER.NAME"),
          fixed: "left",
          align: "left",
          sortBy: this.sortConfig.name || "",
          width: 300,
          renderBodyCell: ({ row }) => (
            <woot-button variant="clear" onClick={() => this.onClickContact(row.id)}>
              <div class="row--user-block">
                <Thumbnail src={row.thumbnail} size="32px" username={row.name} status={row.availability_status} />
                <div class="user-block">
                  <h6 class="sub-block-title user-name text-truncate">{row.name}</h6>
                  <span class="button clear small link">{this.$t("CONTACTS_PAGE.LIST.VIEW_DETAILS")}</span>
                </div>
              </div>
            </woot-button>
          ),
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~dashboard/assets/scss/mixins";

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.contacts-table-wrap::v-deep {
  .ve-table {
    max-width: 100%;
    height: 100%;
    .ve-table-container {
      overflow-x: hidden;
    }
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}
</style>
