/* eslint no-console: 0 */
/* eslint-env browser */
/* eslint-disable no-new */
/* Vue Core */

import Vue from "vue";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import axios from "axios";
// Global Components
import Multiselect from "vue-multiselect";
import VueFormulate from "@braid/vue-formulate";
import WootSwitch from "components/ui/Switch";
import WootWizard from "components/ui/Wizard";
import { sync } from "vuex-router-sync";
import Vuelidate from "vuelidate";
import WootUiKit from "../dashboard/components";
import App from "../dashboard/App";
import i18n from "../dashboard/i18n";
import createAxios from "../dashboard/helper/APIHelper";
import commonHelpers, { isJSONValid } from "../dashboard/helper/commons";
import { initFaviconSwitcher } from "../shared/helpers/faviconHelper";
import router from "../dashboard/routes";
import store from "../dashboard/store";
import vueActionCable from "../dashboard/helper/actionCable";
import constants from "../dashboard/constants";
import { verifyServiceWorkerExistence, registerSubscription } from "../dashboard/helper/pushHelper";
import "vue-easytable/libs/theme-default/index.css";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";

Vue.config.env = process.env;

Sentry.init({
  Vue,
  dsn: "https://ab818d4bcbae40589aabbb7727b74cdd@o1252051.ingest.sentry.io/4505284191518720",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
Vue.use(VueFormulate, {
  rules: {
    JSON: ({ value }) => isJSONValid(value),
  },
});

Vue.component("multiselect", Multiselect);
Vue.component("woot-switch", WootSwitch);
Vue.component("woot-wizard", WootWizard);

const i18nConfig = new VueI18n({
  locale: "en",
  messages: i18n,
});

sync(store, router);
// load common helpers into js
commonHelpers();

window.WootConstants = constants;
window.axios = createAxios(axios);
window.bus = new Vue();
window.onload = () => {
  window.WOOT = new Vue({
    router,
    store,
    i18n: i18nConfig,
    components: { App },
    template: "<App/>",
  }).$mount("#app");
  vueActionCable.init();
};
window.addEventListener("load", () => {
  verifyServiceWorkerExistence((registration) =>
    registration.pushManager.getSubscription().then((subscription) => {
      if (subscription) {
        registerSubscription();
      }
    })
  );
  initFaviconSwitcher();
});
