<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <woot-snackbar
      v-for="snackMessage in snackMessages"
      :key="snackMessage.key"
      :message="snackMessage.message"
    />
  </transition-group>
</template>

<script>
import WootSnackbar from "./Snackbar";
import { LOGIN_ERROR_KEYS } from "dashboard/constants/general";

export default {
  components: {
    WootSnackbar
  },
  props: {
    duration: {
      type: Number,
      default: 2500
    }
  },

  data() {
    return {
      snackMessages: []
    };
  },

  mounted() {
    bus.$on("newToastMessage", message => {
      if (Object.hasOwn(LOGIN_ERROR_KEYS, message)) {
        message = this.$i18n.t(`REQUEST_ERRORS.${LOGIN_ERROR_KEYS[message]}`);
      }
      this.snackMessages.push({ key: new Date().getTime(), message });
      window.setTimeout(() => {
        this.snackMessages.splice(0, 1);
      }, this.duration);
    });
  }
};
</script>
