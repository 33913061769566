<template>
  <div class="resolve-actions">
    <div class="button-group">
      <woot-button
        v-if="isOpen"
        class-names="resolve"
        color-scheme="success"
        icon="ion-checkmark"
        emoji="✅"
        :is-loading="isLoading"
        @click="() => toggleStatus(STATUS_TYPE.RESOLVED)"
      >
      </woot-button>
      <woot-button
        v-else-if="isResolved"
        class-names="resolve"
        color-scheme="warning"
        icon="ion-refresh"
        emoji="👀"
        :is-loading="isLoading"
        @click="() => toggleStatus(STATUS_TYPE.OPEN)"
      >
      </woot-button>
      <woot-button
        v-else-if="isBot"
        class-names="resolve"
        color-scheme="primary"
        icon="ion-person"
        :is-loading="isLoading"
        @click="() => toggleStatus(STATUS_TYPE.OPEN)"
      >
        {{ this.$t("CONVERSATION.HEADER.OPEN_ACTION") }}
      </woot-button>
    </div>
    <div v-if="showDropdown" v-on-clickaway="closeDropdown" class="dropdown-pane dropdown-pane--open">
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="!isBot">
          <woot-button variant="clear" @click="() => toggleStatus(STATUS_TYPE.BOT)">
            {{ this.$t("CONVERSATION.RESOLVE_DROPDOWN.OPEN_BOT") }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import alertMixin from "shared/mixins/alertMixin";

import WootDropdownItem from "shared/components/ui/dropdown/DropdownItem.vue";
import WootDropdownMenu from "shared/components/ui/dropdown/DropdownMenu.vue";
import wootConstants from "../../constants";

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
  },
  mixins: [clickaway, alertMixin],
  props: { conversationId: { type: [String, Number], required: true } },
  data() {
    return {
      isLoading: false,
      showDropdown: false,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: "getSelectedChat",
    }),
    isOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN;
    },
    isBot() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.BOT;
    },
    isResolved() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.RESOLVED || this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    buttonClass() {
      if (this.isBot) return "primary";
      if (this.isOpen) return "success";
      if (this.isResolved) return "warning";
      return "";
    },
    showDropDown() {
      return !this.isBot;
    },
  },
  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
    openDropdown() {
      this.showDropdown = true;
    },
    toggleStatus(status) {
      this.closeDropdown();
      this.isLoading = true;
      this.$store
        .dispatch("toggleStatus", {
          conversationId: this.currentChat.id,
          status,
        })
        .then(() => {
          this.showAlert(this.$t("CONVERSATION.CHANGE_STATUS"));
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.resolve-actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-pane {
  left: unset;
  top: 4.2rem;
  margin-top: var(--space-micro);
  right: 0;
  max-width: 20rem;
}
</style>
