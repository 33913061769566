<template>
  <div :class="emptyClassName">
    <woot-loading-state v-if="uiFlags.isFetching || loadingChatList" :message="loadingIndicatorMessage" />
    <!-- No inboxes attached -->
    <div v-if="!inboxesList.length && !uiFlags.isFetching && !loadingChatList" class="clearfix">
      <div class="current-chat">
        <div>
          <img src="~dashboard/assets/images/inboxes.svg" alt="No Inboxes" />
          <span>
            {{ $t("CONVERSATION.NO_INBOX_AGENT") }}
          </span>
        </div>
      </div>
    </div>
    <!-- Show empty state images if not loading -->
    <div v-else-if="!uiFlags.isFetching && !loadingChatList" class="current-chat">
      <!-- No conversations available -->
      <div v-if="!allConversations.length">
        <img src="~dashboard/assets/images/chat.svg" alt="No Chat" />
        <span>
          {{ $t("CONVERSATION.NO_MESSAGE_1") }}
          <br />
        </span>
        <woot-button class="new-message" size="small" @click="toggleNewConversationModal">{{
          $t("NEW_CONVERSATION.BUTTON_LABEL")
        }}</woot-button>
      </div>
      <!-- No conversation selected -->
      <div v-else-if="allConversations.length && !currentChat.id">
        <img src="~dashboard/assets/images/chat.svg" alt="No Chat" />
        <span style="width: 50%; margin-bottom: 10px;">{{ $t("CONVERSATION.404") }}</span>
        <woot-button class="new-message" size="small" @click="toggleNewConversationModal">{{
          $t("NEW_CONVERSATION.BUTTON_LABEL")
        }}</woot-button>
      </div>
    </div>
    <new-conversation-empty-state
      :show="showNewConversationModal"
      @cancel="toggleNewConversationModal"
      :phone="phone"
      :phoneCode="phoneCode"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import adminMixin from "../../../mixins/isAdmin";
import accountMixin from "../../../mixins/account";
import NewConversationEmptyState from "./NewConversationEmptyState";

export default {
  mixins: [accountMixin, adminMixin],
  components: {
    NewConversationEmptyState: NewConversationEmptyState,
  },
  data() {
    return {
      showNewConversationModal: false,
    };
  },
  props: {
    phone: {
      type: String,
      default: "",
    },
    phoneCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      currentChat: "getSelectedChat",
      allConversations: "getAllConversations",
      inboxesList: "inboxes/getInboxes",
      uiFlags: "inboxes/getUIFlags",
      loadingChatList: "getChatListLoadingStatus",
    }),
    loadingIndicatorMessage() {
      if (this.uiFlags.isFetching) {
        return this.$t("CONVERSATION.LOADING_INBOXES");
      }
      return this.$t("CONVERSATION.LOADING_CONVERSATIONS");
    },
    newInboxURL() {
      return this.addAccountScoping("settings/inboxes/new");
    },
    emptyClassName() {
      if (!this.inboxesList.length && !this.uiFlags.isFetching && !this.loadingChatList && this.isAdmin) {
        return "inbox-empty-state";
      }
      return "columns conv-empty-state";
    },
  },
  methods: {
    toggleNewConversationModal() {
      this.showNewConversationModal = !this.showNewConversationModal;
    },
  },
};
</script>
<style lang="scss" scoped>
.inbox-empty-state {
  height: 100%;
  overflow: auto;
}
.modal-container {
  width: 27rem;
}

.current-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      margin: var(--space-normal);
      width: 10rem;
    }

    span {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      text-align: center;
    }
  }
}

.conv-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
