export const YCLIENTS_APPLICATION_ID = 13;

//Английский - 2, Русский - 1, Португальский - 35
export const AVAILABLE_LANGUAGES_BY_ALTEGIO = [1, 2, 35];

export const ALTEGIO_LANGUAGE_IDS = {
  1: "ru",
  2: "en",
  35: "pt"
};

export const LOGIN_ERROR_KEYS = {
  "Неверные логин или пароль. Пожалуйста, попробуйте еще раз.":
    "INCORRECT_LOGIN_DATA",
  "Email Invalid email": "INCORRECT_EMAIL"
};
