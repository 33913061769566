import Auth from "./Auth";
import { frontendURL } from "../../helper/URLHelper";

export default {
  routes: [
    {
      path: frontendURL("auth"),
      name: "auth",
      component: Auth,
    },
  ],
};
