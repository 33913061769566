/* eslint no-param-reassign: 0 */
import fromUnixTime from "date-fns/fromUnixTime";
import { frontendURL } from "../../helper/URLHelper";

export const getLoadingStatus = (state) => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (userData) => {
  localStorage.setItem("user", JSON.stringify(userData));
};
export const getHeaderExpiry = (response) => fromUnixTime(response.headers.expiry);

export const setAuthCredentials = (response) => {
  localStorage.setItem("auth_data", JSON.stringify(response.headers));

  setUser(response.data.data);
};

export const clearCookiesOnLogout = () => {
  localStorage.removeItem("auth_data");
  localStorage.removeItem("user");

  window.location = frontendURL("login");
};
