export default {
  GRAVATAR_URL: "https://www.gravatar.com/avatar/",
  ASSIGNEE_TYPE: {
    ME: "me",
    UNASSIGNED: "unassigned",
    ALL: "all",
  },
  STATUS_TYPE: {
    OPEN: "open",
    RESOLVED: "resolved",
    SNOOZED: "snoozed",
    ALL: "all",
  },
};
export const DEFAULT_REDIRECT_URL = "/app/";
